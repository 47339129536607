
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElLoading } from 'element-plus'
@Options({
    props: {
        isShowOauth: false,
        urlOauth: ''
    },
})
export default class ConnectWithOauth extends Vue {

}
