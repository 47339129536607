import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_form = _resolveComponent("el-form")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _ctx.isShowOauth,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.isShowOauth = $event)),
    "close-on-click-modal": false,
    width: "80%"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, null, {
        default: _withCtx(() => [
          _createVNode("iframe", {
            src: _ctx.urlOauth,
            width: "100%",
            height: "100",
            frameborder: "0"
          }, "\n            ", 8, ["src"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}